<template>
  <component :is="component" v-bind="$attrs" class="icon" :class="[`icon-${name}`]" />
</template>

<script>
export default {
  name: 'UIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    component() {
      return require(`@/assets/icons/${this.name}.svg?inline`);
    },
  },
};
</script>
