import { coreApi } from '@/api'

const Main = {
  namespaced: true,
  state: {
      isActive: true,
      isLoad: true,
      user: null,
      categories: [],
      tests: [],
      articles: [],
      courses: []
  },
  actions: {
    async init({ state, commit, dispatch }, params) {
      const user = JSON.parse(localStorage.getItem('userData'));
      if (user) {
        const userData =  await coreApi.getUser(user.id)
        commit('setUser', userData)

        const articles = await coreApi.articleList(state.user)
        const tests = await coreApi.testList(state.user)
        const categories = await coreApi.getCategoryAll(state.user.id)
        await dispatch('getCourses')

        commit('setArticles', articles)
        commit('setTests', tests)
        commit('setCategories', categories)
        commit('setIsLoad', false)
      }
    },
    async getArticles({ state, commit }) {
      const articles = await coreApi.articleList(state.user)
      commit('setArticles', articles)
    },
    async getCourses({ state, commit }) {
      const articles = await coreApi.courseList(state.user)
      commit('setCourses', articles)
    },
    async getTests({ state, commit }) {
      console.log('111111');
      const tests = await coreApi.testList(state.user)
      console.log('tests', tests);
      commit('setTests', tests)
    }
  },
  mutations: {
    setIsLoad(state, isLoad) {
      state.isLoad = isLoad
    },
    setUser(state, user) {
      state.user = user
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setArticles(state, articles) {
      if (articles) {
        state.articles = articles
      }
    },
    setCourses(state, courses) {
      if (courses) {
        state.courses = courses
      }
    },
    setTests(state, tests) {
      if (tests) {
        state.tests = tests
      }
    }
  },
}

export default Main
