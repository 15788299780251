<template>
  <div v-if="isFaqVideo" class="faq-video-info">
    <div class="left">
      <div class="title">{{ title }}</div>
      <a :href="link">Смотреть видео</a>
    </div>
    <div class="close" @click="close">
      <feather-icon icon="XIcon" />
    </div>
  </div>
</template>


<script>
export default {
  name: 'FaqVideoInfo',
  props: {
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isFaqVideo: true
    }
  },
  methods: {
    close() {
      this.isFaqVideo = false
    }
  }
}
</script>