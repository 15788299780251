export default [
    {
      path: '/account-setting',
      name: 'account-setting',
      component: () => import('@/views/setting/account/Setting.vue'),
    },  
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/Faq.vue'),
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: () => import('@/views/Pricing.vue'),
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/views/Support.vue'),
    }
  ]
  