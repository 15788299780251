import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
import useJwt from '@/api/auth/jwt/useJwt'


export const config = {
  headers: {
    ContentType: 'application/json',
    Accept: 'application/json',
    AppType: 'testing'
  },
}

export default class Api {
  constructor(options = {}) {
    const token = useJwt.getToken()

    this.client = options.client || axios.create(config)

    this.client.interceptors.request.use(
      (config) => {
        if (!token) {
          return config
        }

        if (config.method === 'post') {
          // store.commit('startLoading')
        }

        const newConfig = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            token
          },
          ...config,
        }

        newConfig.headers.token = token
        return newConfig
      },
      (error) => {
        if (error.config.method === 'post') {
          // store.commit('stopLoading')
        }
        return Promise.reject(error)
      }
    )

    this.client.interceptors.response.use(
      (response) => {
        if (response.config.method === 'post') {
          // store.commit('stopLoading')
        }
        return response
      },
      async (error) => {
        console.log('error', error);
        // if (error?.config?.method === 'post') {
        //   // store.commit('stopLoading')
        // }
        // const vm = new Vue()
        // const message = 'Что-то пошло не так...'

        // if (error?.response.status !== 401) {
        //   console.error(message)
        //   if (
        //     Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') &&
        //     error?.config.errorHandle === false
        //   ) {
        //     return Promise.reject(error)
        //   }
        // }
        // // await store.dispatch('auth/logout')
      }
    )
  }
}
