import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import main from './modules/main'
import wiki from './modules/wiki'

// NEW
import courses from './st-modules/courses'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    main,
    wiki,

    // new
    courses,
  },
  strict: process.env.DEV,
});
