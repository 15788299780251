import { coreApi } from '@/api';

const Wiki = {
  namespaced: true,
  state: {
    idWiki: 0,
    user: {},
    structure: [],

    currentId: 0,
    currentPage: null,
  },
  actions: {
    async init({ commit, dispatch }, { user, route }) {
      const data = await coreApi.wikiGetStructure({ userId: user.id })

      if (data) {
        const structure = data.structure ? JSON.parse(data.structure) : []

        commit('setIdWikiInBase', data.id)
        commit('setCurrentId', data.lastId)
        commit('setStructure', structure)

        const id = route?.query?.id

        console.log('id', id)
        if (id) {
          console.log('structure', structure);
          const article = findElementByIdAndType(structure, id, 2)
          console.log('article', article)
          commit('setCurrentPage', article)
        }
      }

      commit('setUser', user);
      dispatch('saveStructure');
    },
    saveStructure({ state, commit }) {
      setInterval(async () => {
        const id = await coreApi.wikiUpdateStructure({
          userId: state.user.id,
          id: state.idWiki,
          lastId: state.currentId,
          structure: JSON.stringify(state.structure || []),
        });

        if (id) {
          commit('setIdWikiInBase', id)
        }
      }, 8000);
    },
    nextId({ state }) {
      return ++state.currentId;
    },
    deleteFolder({ state, commit }, node) {
      state.structure.find(function findEl(el, index, array) {
        if (el?.id === node?.id) {
          console.log('node', node);
          if (node.type === 2) {
            coreApi.wikiArticleDelete(node);
          }
          commit('setCurrentPage', null);
          array.splice(index, 1);
        } else if (el?.node?.length) {
          el?.node?.find(findEl);
        }
      });
    },
    addFolder({ state, commit }, node) {
      if (state.currentPage) {
        state.structure.find(function findEl(el, index, array) {
          if (el?.id === state.currentPage.id) {
            array[index].node.push(node);
          } else if (el?.node?.length) {
            el?.node?.find(findEl);
          }
        });
      } else {
        state.structure.push(node);
        commit('setCurrentPage', node);
      }
    },
  },
  mutations: {
    setCurrentId(state, id) {
      state.currentId = id;
    },
    setIdWikiInBase(state, id) {
      state.idWiki = id;
    },
    setCurrentPage(state, currentPage) {
      if (currentPage?.type === 2) {
        history.pushState(null, null, `/wiki?id=${currentPage.id}`);
      //
      } else {
        if (document.location.href.replace(document.location.origin, '') !== '/wiki')
          history.pushState(null, null, `/wiki`);
      }

      state.currentPage = currentPage;
    },
    setUser(state, user) {
      state.user = user;
    },
    setStructure(state, structure) {
      state.structure = structure;
    },
  },
};

function findElementByIdAndType(dataArray, id, type) {
  for (const element of dataArray) {
    const result = findInNode(element, id, type);
    if (result) {
      return result;
    }
  }

  return null;
}

function findInNode(node, id, type) {
  if (node.id == id && node.type == type) {
    return node;
  }

  for (const childNode of node.node) {
    const result = findInNode(childNode, id, type);
    if (result) {
      return result;
    }
  }

  return null;
}


export default Wiki;
