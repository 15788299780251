<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <div class="link-site">
      <div class="title">Сайт для пользователя</div>
      <div class="link" @click="copy">
        <input type="text" value="https://app.eddy24.ru/" />
        <UIcon
          name="copy"
        />
      </div>
    </div>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    ToastificationContent,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText('https://app.eddy24.ru/');
      this.$toast("Ссылка на сайт скопирована");
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>

<style lang="scss">
.link-site {
  background: #149BDF;
  color: #fff;
  border-radius: 10px;
  width: calc(100% - 30px);
  margin-left: 15px;
  padding: 20px 10px;
  margin-top: 20px;

  .title {
    font-weight: 500;
    margin-bottom: 12px;
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1891CD;
    padding: 7px;
    border-radius: 4px;

    input {
      background: none;
      font-size: 14px;
    }

    .icon {
      fill: #fff;
      cursor: pointer;
    }
  }
}
</style>
