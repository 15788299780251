import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/api/auth/utils'
import auth from './routers/auth'
import profile from './routers/profile'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'main' } },
    ...auth,
    ...profile,
    {
      path: '/main',
      name: 'main',
      component: () => import('@/views/main/main.vue'),
    },
    {
      path: '/tests',
      name: 'tests',
      component: () => import('@/views/tests/Tests.vue'),
    },
    {
      path: '/test/:id',
      name: 'test-id',
      component: () => import('@/views/tests/Test.vue'),
    },


    {
      path: '/courses',
      name: 'courses',
      component: () => import('@/views/course/Courses.vue'),
    },

    {
      path: '/wiki',
      name: 'wiki',
      component: () => import('@/views/wiki/Wiki.vue'),
    },

    {
      path: '/wiki/main',
      name: 'wikiMain',
      component: () => import('@/views/wiki/Main.vue'),
    },

    {
      path: '/wiki/settings',
      name: 'wikiSettings',
      component: () => import('@/views/wiki/Settings.vue'),
    },

    {
      path: '/course/:id',
      name: 'course-id',
      component: () => import('@/views/course/Course.vue'),
    },


    {
      path: '/materials',
      name: 'materials',
      component: () => import('@/views/materials/Materials.vue'),
    },
    {
      path: '/material/:id',
      name: 'material-id',
      component: () => import('@/views/materials/Material.vue'),
    },

    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('@/views/Statistics.vue'),
    },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('@/views/employees/index.vue'),
    },
    {
      path: '/employees/:id',
      name: 'employees-id',
      component: () => import('@/views/employees/employee.vue'),
    },
    {
      path: '/reset-password/:hash',
      name: 'resetPassword',
      component: () => import('@/views/setting/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    // {
    //   path: '/test/:id',
    //   name: 'test-id',
    //   component: () => import('@/views/tests/Test.vue'),
    // },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
