import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import '@/plugins/components'

import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import FaqVideoInfo from '@/components/base/FaqVideoInfo'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.component('FaqVideoInfo', FaqVideoInfo)

// Composition API
Vue.use(VueCompositionAPI)


// My plugin
// import VPopover from 'vue-js-popover'
// Vue.use(VPopover, { tooltip: true })
import VBTooltip from 'bootstrap-vue'
Vue.use(VBTooltip)

import ClickOutside from 'vue-click-outside';
Vue.directive('click-outside', ClickOutside);


// TOAST - NEW
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 1,
  newestOnTop: true,
});

require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
require('@core/scss/core.scss')
require('@/assets/scss/style.scss')
require('@/assets/scss/new.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
