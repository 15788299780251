/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Access Control', route: { name: 'access-control' }, icon: 'ShieldIcon', isBookmarked: false },
    ],
  },
  files: {
    key: 'file_name',
    data: [],
  },
  contacts: {
    key: 'name',
    data: [],
  },
}
/* eslint-enable */
