/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

export default [
  {
    title: 'Главная',
    icon: 'HomeIcon',
    route: 'main',
  },
  {
    title: 'Курсы',
    icon: 'ColumnsIcon',
    route: 'courses',
  },
  {
    title: 'Тесты',
    icon: 'CheckSquareIcon',
    route: 'tests',
  },
  {
    title: 'Материалы',
    icon: 'BookOpenIcon',
    route: 'materials',
  },
  {
    title: 'Статистика',
    icon: 'PieChartIcon',
    route: 'statistics',
  },
  {
    title: 'Сотрудники',
    icon: 'UsersIcon',
    route: 'employees',
  },
  {
    title: 'База знаний',
    icon: 'BookIcon',
    route: 'wiki',
  },
  {
    header: 'Дополнительно',
  },
  {
    title: 'Настройки',
    route: 'account-setting',
    icon: 'SettingsIcon',
  },
  {
    title: 'Подписка',
    route: 'pricing',
    icon: 'CreditCardIcon',
  },
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
  },
  {
    title: 'Техподдержка',
    route: 'support',
    icon: 'HeadphonesIcon',
  }
]