import Api from '@/api/api'

class CoreApi extends Api {
  constructor() {
    super()
    // this.client.defaults.baseURL = 'https://testing.b24apps.ru/api/v1'
    this.client.defaults.baseURL = 'https://api.eddy24.ru/api/v1'
  }

  // SETTINGS
  async getCategoryAll(id) {
    const { data } = await this.client.post(`/settings/category/all`, {id});
    return data;
  }

  async updateCategory(params) {
    const { data } = await this.client.post(`/settings/category`, params);
    return data;
  }





  // USER
  async userUpdate(params) {
    const { data } = await this.client.post(`/user/update`, params);
    return data;
  }

  async passwordUpdate(params) {
    const { data } = await this.client.post(`/user/password/update`, params);
    return data;
  }

  async getUser(id) {
    const { data } = await this.client.post(`/user/get`, { id });
    return data;
  }




  // EMPLOYEES
  async employees(userId) {
    const { data } = await this.client.post(`/settings/employees/all`, { userId });
    return data;
  }

  async employeesAdd(params) {
    const { data } = await this.client.post(`/settings/employees/add`, params);
    return data;
  }

  async employeesUpdate(params) {
    const { data } = await this.client.post(`/settings/employees/update`, params);
    return data;
  }

  async employeesDelete(params) {
    const { data } = await this.client.post(`/settings/employees/delete`, params);
    return data;
  }




  // ARTICLE
  async articleList(params) {
    const { data } = await this.client.post(`/article/list`, params);
    return data;
  }

  async article(params) {
    const { data } = await this.client.post(`/article/get`, params);
    return data;
  }

  async articleUpdate(params) {
    const { data } = await this.client.post(`/article/update`, params);
    return data;
  }

  async articleCreate(params) {
    const { data } = await this.client.post(`/article/create`, params);
    return data;
  }

  async articleDelete(id) {
    const { data } = await this.client.post(`/article/delete`, { id });
    return data;
  }

  // COURSE
  async courseList(params) {
    const { data } = await this.client.post(`/course/list`, params);
    return data;
  }

  async course(params) {
    const { data } = await this.client.post(`/course/get`, params);
    return data;
  }

  async courseUpdate(params) {
    const { data } = await this.client.post(`/course/update`, params);
    return data;
  }

  async courseCreate(params) {
    const { data } = await this.client.post(`/course/create`, params);
    return data;
  }

  async courseDelete(id) {
    const { data } = await this.client.post(`/course/delete`, { id });
    return data;
  }


  // TESTS
  async testList(params) {
    const { data } = await this.client.post(`/test/list`, params);
    return data;
  }

  async test(params) {
    const { data } = await this.client.post(`/test/get`, params);
    return data;
  }

  async testUpdate(params) {
    const { data } = await this.client.post(`/test/update`, params);
    return data;
  }

  async testCreate(params) {
    const { data } = await this.client.post(`/test/create`, params);
    return data;
  }

  async testDelete(id) {
    const { data } = await this.client.post(`/test/delete`, { id });
    return data;
  }

  // FAQ
  async faqList() {
    const { data } = await this.client.get(`/faq/list`);
    return data;
  }

  // STATISTICS
  async getStatistics(id) {
    const { data } = await this.client.post(`/statistics/list`, { id });
    return data;
  }

  // SETTINGS
  async resetPassword(email) {
    const { data } = await this.client.post(`/user/password/reset`, { email });
    return data;
  }

  async resetPasswordUpdate(params) {
    const { data } = await this.client.post(`/user/password/reset/update`, params);
    return data;
  }

  async resetPasswordCheckHash(hash) {
    const { data } = await this.client.post(`/user/password/check/hash`, { hash });
    return data;
  }

  // FILE
  async uploadFile(file, user, type) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user', user.id);
    formData.append('type', type);

    const { data } = await this.client.post(`/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (data) {
      return {
        success: 1,
        file: data
      }
    } else {
      return false
    }
  }

  // INTEGRATION BITRIX24
  async getUsersFromB24(params) {
    const { data } = await this.client.post(`/bitrix/users`, params);
    return data;
  }

  async addUsersFromB24(params) {
    const { data } = await this.client.post(`/settings/employees/addFromB24`, params);
    return data;
  }

  // wiki
  async wikiGetStructure(params) {
    const { data } = await this.client.post(`/wiki/structure`, params);
    return data;
  }

  async wikiUpdateStructure(params) {
    const { data } = await this.client.post(`/wiki/structure-update`, params);
    return data;
  }

  async wikiArticleAdd(params) {
    const { data } = await this.client.post(`/wiki/article-add`, params);
    return data;
  }

  async wikiArticleUpdate(params) {
    const { data } = await this.client.post(`/wiki/article-update`, params);
    return data;
  }

  async wikiArticleDelete(params) {
    const { data } = await this.client.post(`/wiki/article-delete`, params);
    return data;
  }

  async wikiArticleGet(params) {
    const { data } = await this.client.post(`/wiki/article-get`, params);
    return data;
  }

  async wikiSearch(params) {
    const { data } = await this.client.post(`/wiki/search`, params);
    return data;
  }


  // EMPLOYEES
  async employeProgress(id) {
    const { data } = await this.client.post(`/employee/progress`, { id });
    return data;
  }

  async sendPasswordUser(params) {
    const { data } = await this.client.post(`/employee/send-password`, { ...params });
    return data;
  }
}




export default CoreApi
