<template>
  <div class="preloader" :data-small="true">
    <div class="dots">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px 0;

  &.loader {
    .dot {
      width: 8px !important;
      height: 8px !important;
      background-color: #ffffff !important;
    }
  }

  .dots {
    display: flex;

    .dot {
      width: 10px;
      height: 10px;
      background-color: var(--primary);
      border-radius: 50%;
      transition: all 0.2s;
      animation: dot 1.5s infinite linear;
      opacity: 1;
      transform: scale(1);
      &:nth-child(2) {
        animation-delay: 0.3s;
      }
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    @keyframes dot {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      13.33% {
        opacity: 0;
        transform: scale(0.5);
      }
      40% {
        opacity: 0;
        transform: scale(0.5);
      }
      53.33% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .default {
    svg {
      overflow: visible;
      path {
        position: relative;
        transform-origin: 50% 50%;
        &:first-child {
          animation: outerPath 2s infinite linear;
        }
        &:nth-child(2) {
          animation: innerPath 2s infinite linear;
        }
      }
    }

    &[data-small='true'] {
      transform: scale(0.5);
    }

    @keyframes innerPath {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes outerPath {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
