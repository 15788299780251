import { coreApi } from '@/api'

const Courses = {
  namespaced: true,
  state: {
    courses: [],
    employees: [],

    tests: [],
    materials: [],
  },
  actions: {
    async init({ dispatch }) {
      await dispatch('getEmployees');
      await dispatch('getCourses');
    },
    async getEmployees({ rootState, commit }) {
      let employees = await coreApi.employees(rootState.main.user.id);
      employees = employees.map((user) => {
        let { name: initials } = user;
        initials = initials.split(' ').slice(0, 2).map((initial) => initial[0]?.toUpperCase())?.join('');
        return {
          ...user,
          initials,
        }
      });
      commit('setEmployees', employees);
    },
    async getCourses({ rootState, state, commit }) {
      let courses = await coreApi.courseList(rootState.main.user);
      courses = courses?.map((course) => {
        const { employees, description, active } = course;

        return {
          ...course,
          active: active === '0' ? false : true,
          employees:
            employees
              .split('|')
              .map((item) => state.employees
              .find(employee => employee.id == item))
              .filter((item) => item),
          desc: description.length > 10 ? `${description.slice(0, 75)}...` : description,
        };
      }) || [];

      commit('setCourses', courses);
    },

    // course
    async initCourse({ dispatch, rootState, state, commit }, id) {
      if (!state.courses.length)
        await dispatch('init');

      if (id > 0) {
        commit('setMaterials', await coreApi.articleList(rootState.main.user));
        commit('setTests', await coreApi.testList(rootState.main.user));

        const course = state.courses.find((course) => course.id == id);
        const structure = await dispatch('formirationStructure', course);

        return {
          ...course,
          isStepByStep: course.isStepByStep === "0" ? false : true,
          structure,
        };
      }

      return {
        title: '',
        description: '',
        employees: [],
        active: true,
      };
    },
    async formirationStructure({ state }, course) {
      let { structure } = course;
      if (structure) {
        const listEntity = structure.split(' | ');

        structure = listEntity.map((item) => {
          let itemFind = '';
          const itemCurrent = item.split(' - ');

          if (itemCurrent[1] === 'material') {
            itemFind = state.materials.find((item) => item.id === itemCurrent[0])
          } else {
            itemFind = state.tests.find((item) => item.id === itemCurrent[0])
          }

          return {
            ...itemFind,
            type: itemCurrent[1] === 'material' ? 'material' : 'test'
          }
        })

        return structure;
      }
      return [];
    },

    // meterial
    async getMaterial({ rootState, commit }) {
      commit('setMaterials', await coreApi.articleList(rootState.main.user));
    },

    // test
    async getTests({ rootState, commit }) {
      commit('setTests', await coreApi.testList(rootState.main.user));
    }
  },
  mutations: {
    setCourses(state, courses) {
      state.courses = courses;
    },
    setEmployees(state, employees) {
      state.employees = employees;
    },
    setMaterials(state, data) {
      state.materials = data;
    },
    setTests(state, data) {
      state.tests = data;
    },
  },
};

export default Courses;
