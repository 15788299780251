<template>
  <LayoutVertical>
    <Preloader v-if="isLoad" />
    <router-view v-else />
  </LayoutVertical>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import Preloader from '@/components/base/Preloader.vue'
import { BModal } from 'bootstrap-vue'


export default {
  components: {
    LayoutVertical,
    Preloader,
    BModal
  },
  watch: {
    '$route.path'() {
      if (!this.isLoad) {
        // check tarif
        if (Number(this.user?.tariffType) === 3) {
          if (this.$route.name !== 'pricing') {
            this.$router.replace(`/pricing`)
          }
        }
      }
    }
  },
  computed: {
    ...mapState('main', ['isLoad', 'user'])
  },
  methods: {
    ...mapActions('main', [
      'init'
    ]),
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss">
#loading-bg {
  position: revert;
}

.new-modal-education {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  background: #0000005e;
  z-index: 1111;
  justify-content: center;

  .wrapper-data {
    padding: 40px 45px;
    border-radius: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > .title {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 12px;
      color: #000000;
    }

    & > img {
      width: 200px;
      height: 200px;
    }

    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #797979;
      margin-bottom: 30px;
    }

    .button-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        width: 170px;
        height: 40px;
        background: #0088CC;
        border-radius: 7px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.soft {
          background: rgba(0, 136, 204, 0.1);
          color: #0088CC;
        }

        &:first-child {
          margin-right: 10px;
        }

        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
</style>
